<template>
    <main>
      <div class="card-title mt-2">   
        <h3 class="text-dark">Códigos Promocionales</h3> 
      </div>
      <div class="dropdown m-1">
        <button class="btn border-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          Agregar Código (Consultar Admin)
        </button>
        <ul class="dropdown-menu">
          <li>
            <a class="dropdown-item" style="cursor: pointer;" data-bs-toggle="modal" data-bs-target="#modalProfesionista">
              Para Profesionista
            </a>
          </li>
          <li>
            <a class="dropdown-item" style="cursor: pointer;" data-bs-toggle="modal" data-bs-target="#modalUniversitario">
              Para Universitario
            </a>
          </li>
          <li>
            <a class="dropdown-item" style="cursor: pointer;" data-bs-toggle="modal" data-bs-target="#modalPreUniversitario">
              Para Pre-Universitario
            </a>
          </li>
        </ul>
      </div>
      <!-- Modals -->
      <div class="modal fade" id="modalProfesionista" tabindex="-1" aria-labelledby="modalProfesionistaLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="modalProfesionistaLabel">Código Promocional para Profesionista</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="modalClose"></button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="addPromo('EX-UNIVERSITARIO')">
                <div class="mb-3">
                  <label for="promoCodeProf" class="form-label">Código Promocional</label>
                  <input type="text" class="form-control" id="promoCodeProf" v-model="newPromo.code" required>
                </div>
                <div class="mb-3">
                  <label for="promoDescriptionProf" class="form-label">Descripción</label>
                  <input type="text" class="form-control" id="promoDescriptionProf" v-model="newPromo.description" required>
                </div>
                <div class="mb-3">
                  <label for="promoExpirationDateProf" class="form-label">Fecha de Expiración</label>
                  <input type="date" class="form-control" id="promoExpirationDateProf" v-model="newPromo.expirationDate" required>
                </div>
                <button type="submit" class="btn btn-success">Guardar cambios</button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="modalUniversitario" tabindex="-1" aria-labelledby="modalUniversitarioLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="modalUniversitarioLabel">Código Promocional para Universitario</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="modalClose"></button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="addPromo('UNIVERSITARIO')">
                <div class="mb-3">
                  <label for="promoCodeUni" class="form-label">Código Promocional</label>
                  <input type="text" class="form-control" id="promoCodeUni" v-model="newPromo.code" required>
                </div>
                <div class="mb-3">
                  <label for="promoDescriptionUni" class="form-label">Descripción</label>
                  <input type="text" class="form-control" id="promoDescriptionUni" v-model="newPromo.description" required>
                </div>
                <div class="mb-3">
                  <label for="promoExpirationDateUni" class="form-label">Fecha de Expiración</label>
                  <input type="date" class="form-control" id="promoExpirationDateUni" v-model="newPromo.expirationDate" required>
                </div>
                <button type="submit" class="btn btn-success">Guardar cambios</button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="modalPreUniversitario" tabindex="-1" aria-labelledby="modalPreUniversitarioLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="modalPreUniversitarioLabel">Código Promocional para Pre-Universitario</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="modalClose"></button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="addPromo('PREUNIVERSITARIO')">
                <div class="mb-3">
                  <label for="promoCodePreUni" class="form-label">Código Promocional</label>
                  <input type="text" class="form-control" id="promoCodePreUni" v-model="newPromo.code" required>
                </div>
                <div class="mb-3">
                  <label for="promoDescriptionPreUni" class="form-label">Descripción</label>
                  <input type="text" class="form-control" id="promoDescriptionPreUni" v-model="newPromo.description" required>
                </div>
                <div class="mb-3">
                  <label for="promoExpirationDatePreUni" class="form-label">Fecha de Expiración</label>
                  <input type="date" class="form-control" id="promoExpirationDatePreUni" v-model="newPromo.expirationDate" required>
                </div>
                <button type="submit" class="btn btn-success">Guardar cambios</button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="modalUser" tabindex="-1" aria-hidden="true" aria-labelledby="modalTitle"
          data-bs-backdrop="status">
          <div class="modal-dialog modal-md modal-dialog-scrollable">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" id="modalTitle">Editar Código Promocional</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <form @submit.prevent="savedData()" class="center" style="display: flex; flex-direction: column; gap: 5px; margin-top: 5%">
                        <div>
                            <p style="font-weight: bolder">Código:</p>
                            <input type="text" v-model="viewDataArray.code" class="form-control" id="nameComercialInput" placeholder="Editar Código" />
                        </div>
                        <div class="mt-2">
                            <p style="font-weight: bolder">Descripción:</p>
                            <input type="text" v-model="viewDataArray.description" class="form-control" id="nameComercialInput" placeholder="Editar Descripción" />
                        </div>
                        <div class="mt-2">
                            <p style="font-weight: bolder">Fecha de Vigencia:</p>
                            <input type="date" class="form-control" id="promoExpirationDatePreUni" v-model="viewDataArray.expirationDate" required>   
                        </div>
                    </form>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-success" data-bs-dismiss="modal" @click="savedData()">
                          Guardar Cambios
                      </button>
                      <!-- <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="deleteUser()">
                          Borrar Usuario
                      </button> -->
                  </div>
              </div>
          </div>
      </div>
        <!-- Tabla de Códigos Promocionales -->
        <table class="table align-middle table-responsive table-hover caption-top mt-4">
            <thead>
                <tr>
                    <th>Código</th>
                    <th>Descripción</th>
                    <!-- <th>Fecha de Creación</th>
                    <th>Fecha de Expiración</th> -->
                    <th>Tipo (No Editable)</th>
                    <th>Opciones</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="promo in promoCodes" :key="promo.code">
                    <td>{{ promo.code }}</td>
                    <td>{{ promo.description }}</td>
                    <!-- <td>{{ moment(promo.createdAt).format('DD/MM/YYYY') }}</td>
                    <td>{{ moment(promo.expirationDate).format('DD/MM/YYYY') }}</td> -->
                    <td>{{ promo.type }}</td>
                    <td>
                          <button type="button" class="btn btn-icon btn-success btn-hover-primary btn-sm mx-3"
                              data-bs-toggle="modal" data-bs-target="#miModal" @click="viewData(promo)"
                              title="Editar Código">
                              <box-icon type='solid' color="white" name='edit'></box-icon>
                          </button>
                      </td>
                </tr>
            </tbody>
        </table>
    </main>
</template>

<script>
  import { AdminService } from "@/services";
  import moment from "moment";
  moment.locale("es");    
  
  export default {
    data: () => ({
      promoCodes: {},
      viewDataArray: {},
      newPromo: {
        code: '',
        description: '',
        expirationDate: '',
        type: '' // Para identificar el tipo de código
      },
      response: {},
      moment: moment,
    }),
    created() {
     this.loadPromoCodes()
  },
    methods: {
        async loadPromoCodes() {
            try {
            const response = await AdminService.getAllPromosAdmin();
            this.promoCodes = response.data;
            } catch (error) {
            console.error('Error al cargar los códigos promocionales:', error);
            }
        },
        // viewData(promo) {
        //     this.viewDataArray = promo;
        //     this.id = promo._id;
        //     let myModal = new window.bootstrap.Modal('#modalUser', { keyboard: false })
        //     myModal.show();
        // },
        // async addPromo(type) {
        //     this.newPromo.type = type;
        //     const result = await this.$swal({
        //         title: "¿Está seguro de que desea enviar esta promoción?",
        //         text: "Una vez enviada, no podrá modificarla y tendrá que crear una nueva promoción.",
        //         icon: "warning",
        //         showCancelButton: true,
        //         confirmButtonColor: "#3085d6",
        //         cancelButtonColor: "#d33",
        //         confirmButtonText: "Sí, enviar",
        //         cancelButtonText: "Cancelar",
        //     });
        //     if (result.isConfirmed) {
        //         try {
        //             const response = await AdminService.addPromo(this.newPromo);
        //             this.$swal({
        //                 position: "top-center",
        //                 icon: "success",
        //                 title: response.data.message,
        //                 showConfirmButton: false,
        //                 timer: 4000,
        //             });
        //             this.newPromo = { code: '', description: '', expirationDate: '' }; 
        //             const modaleRecovery = document.getElementById("modalClose");
        //             modaleRecovery.click();
        //             this.loadPromoCodes();
        //         } catch (error) {
        //             this.$swal({
        //                 position: "top-end",
        //                 icon: "error",
        //                 title: error.response.data.message,
        //                 showConfirmButton: false,
        //                 timer: 2500,
        //             });
        //         }
        //     }
        // },
        // async savedData() {
        //     try {
        //     const response = await AdminService.updatePromo(this.id, this.viewDataArray); // Cambia esto por el método real que tengas en tu servicio
        //     this.$swal({
        //         position: "top-center",
        //         icon: "success",
        //         title: response.data.message,
        //         showConfirmButton: false,
        //         timer: 4000,
        //     });
        //     this.loadPromoCodes(); // Recarga los códigos promocionales después de la actualización
        //     const modaleRecovery = document.getElementById("modalClose"); // Cierra el modal
        //     modaleRecovery.click();
        //     } catch (error) {
        //     this.$swal({
        //         position: "top-end",
        //         icon: "error",
        //         title: error.response.data.message,
        //         showConfirmButton: false,
        //         timer: 2500,
        //     });
        //     }
        // },

        viewData(promo) {
            this.viewDataArray = promo;
            this.id = promo._id;
            let myModal = new window.bootstrap.Modal('#modalUser', { keyboard: false });
            myModal.show();
        },
        async addPromo(type) {
            this.newPromo.type = type;
            
            // Muestra la alerta de confirmación
            const result = await this.$swal({
                title: "¿Está seguro de que desea enviar esta promoción?",
                text: "Una vez enviada, no podrá modificarla y tendrá que crear una nueva promoción.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sí, enviar",
                cancelButtonText: "Cancelar",
            });

            if (result.isConfirmed) {
                try {
                    const response = await AdminService.addPromo(this.newPromo);
                    this.$swal({
                        position: "top-center",
                        icon: "success",
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 4000,
                    });
                    this.newPromo = { code: '', description: '', expirationDate: '', type: '' };
                    let modalId = '';
                    if (type === 'EX-UNIVERSITARIO') modalId = 'modalProfesionista';
                    else if (type === 'UNIVERSITARIO') modalId = 'modalUniversitario';
                    else if (type === 'PREUNIVERSITARIO') modalId = 'modalPreUniversitario';
                    const modalCloseButton = document.querySelector(`#${modalId} .btn-close`);
                    if (modalCloseButton) modalCloseButton.click();
                    this.loadPromoCodes();
                } catch (error) {
                    this.$swal({
                        position: "top-end",
                        icon: "error",
                        title: error.response?.data?.message || "Error al agregar la promoción",
                        showConfirmButton: false,
                        timer: 2500,
                    });
                }
            }
        },
        async savedData() {
            try {
                console.log("envio", this.viewDataArray)
                const response = await AdminService.updatePromo(this.viewDataArray);
                this.$swal({
                    position: "top-center",
                    icon: "success",
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 4000,
                });
                this.loadPromoCodes();
                const modalCloseButton = document.querySelector('#modalUser .btn-close');
                if (modalCloseButton) modalCloseButton.click();
            } catch (error) {
                this.$swal({
                    position: "top-end",
                    icon: "error",
                    title: error.response?.data?.message || "Error al guardar los cambios",
                    showConfirmButton: false,
                    timer: 2500,
                });
            }
        },  
    },
  };
</script>

